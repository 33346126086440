import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Link, CircularProgress, Fade } from '@mui/material';
import { fetchNews } from '../utils/newsUtils';
import { getCountryCode, getFlagEmoji } from '../utils/countryUtils';

function NewsColumn({ category, bias, refreshKey }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const fetchedArticles = await fetchNews(category, bias);
        setArticles(fetchedArticles);
        setError(null);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError('Failed to fetch articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [category, bias, refreshKey]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (articles.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No articles found for this category and bias. Try adjusting the bias slider.</Typography>
      </Box>
    );
  }

  const renderArticle = (article, index) => {
    const countryCode = getCountryCode(article);
    const flagEmoji = getFlagEmoji(countryCode);

    console.log('Article title before rendering:', article.title);

    return (
      <Fade in={true} timeout={500} key={index}>
        <Card sx={{ 
          mb: 2, 
          overflow: 'hidden',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: (theme) => theme.shadows[10],
          }
        }}>
          <CardContent sx={{ py: 1.5, px: 2, position: 'relative' }}>
            <Link 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              <Typography 
                variant="subtitle1" 
                component="div"
                sx={{ 
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': {
                    color: 'primary.main',
                  }
                }}
              >
                {flagEmoji} {article.title} {/* Display the title as is */}
              </Typography>
            </Link>
            <Typography 
              variant="caption" 
              sx={{ 
                position: 'absolute', 
                bottom: 4, 
                right: 8, 
                color: 'text.secondary'
              }}
            >
              {article.domain}
            </Typography>
          </CardContent>
        </Card>
      </Fade>
    );
  };

  const getColumnTitle = () => {
    switch(category) {
      case 'politics':
        return 'POLITICS';
      case 'business-tech':
        return 'BUSINESS & TECH';
      case 'entertainment-sports':
        return 'ENTERTAINMENT';
      default:
        return category.toUpperCase();
    }
  };

  return (
    <Box>
      <Typography 
        variant="h6" 
        gutterBottom 
        align="center"
        sx={{ 
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        {getColumnTitle()}
      </Typography>
      {articles.map(renderArticle)}
    </Box>
  );
}

export default NewsColumn;
