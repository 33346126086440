import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import boltIcon from '../assets/images/BoltAlpha_128.png'; // Import the bolt icon

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <Box 
      sx={{
        background: theme.palette.mode === 'dark'
          ? 'linear-gradient(135deg, #1e3c72 0%, #2a5298 50%, #7474BF 100%)'
          : 'linear-gradient(135deg, #00c6ff 0%, #0072ff 50%, #00c6ff 100%)',
        borderRadius: '16px',
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3vmin',
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '1vmin',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Typography variant="h2" component="div" sx={{ 
          fontWeight: 'bold',
          color: 'white',
          fontStyle: 'italic',
          textShadow: '0.2vmin 0.2vmin 0.4vmin rgba(0,0,0,0.3)',
          whiteSpace: 'nowrap',
          marginRight: '0.2vmin',
          fontSize: '6vmin',
        }}>
          FLASH
        </Typography>
        <img 
          src={boltIcon} 
          alt="Bolt" 
          style={{ 
            height: '8vmin',
            margin: '0 0.2vmin 0 1.6vmin',
          }} 
        />
        <Typography variant="h2" component="div" sx={{ 
          fontWeight: 'bold',
          color: 'white',
          fontStyle: 'italic',
          textShadow: '0.2vmin 0.2vmin 0.4vmin rgba(0,0,0,0.3)',
          whiteSpace: 'nowrap',
          marginLeft: '0.2vmin',
          fontSize: '6vmin',
        }}>
          TOPICS
        </Typography>
      </Box>
      <Typography sx={{ 
        textAlign: 'center',
        display: 'flex',
        flexDirection: isMobile && isPortrait ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '2.5vmin',
        marginBottom: '1vmin',
      }}>
        <span>KNOW YOUR BIAS</span>
        {(!isMobile || !isPortrait) && <span>&nbsp;-&nbsp;</span>}
        <span>KNOW YOUR SOURCE</span>
      </Typography>
      <Typography sx={{ 
        color: 'rgba(255,255,255,0.8)',
        fontStyle: 'italic',
        textAlign: 'center',
        fontSize: '2vmin',
        marginBottom: '1vmin',
      }}>
        {formattedDate}
      </Typography>
      <Typography sx={{ 
        color: 'rgba(255,255,255,0.8)',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '1.8vmin',
      }}>
        US Edition
      </Typography>
    </Box>
  );
}

export default Header;
