import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Link } from '@mui/material';
import { fetchHeadlines } from '../utils/newsUtils';
import { useTheme } from '@mui/material/styles';

const HeadlinesColumn = ({ refreshKey, bias }) => {
  const [headlines, setHeadlines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const getHeadlines = async () => {
      setLoading(true);
      try {
        const fetchedHeadlines = await fetchHeadlines(bias);
        setHeadlines(fetchedHeadlines);
        setError(null);
      } catch (err) {
        console.error('Error fetching headlines:', err);
        setError('Failed to fetch headlines. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    getHeadlines();
  }, [refreshKey, bias]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading headlines...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ width: '100%', margin: '0 auto' }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        align="center"
        sx={{ 
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        HEADLINES
      </Typography>
      {headlines.map((article, index) => (
        <Card 
          key={index} 
          sx={{ 
            mb: 2, 
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            '&:hover': {
              boxShadow: theme.shadows[4],
              transform: 'translateY(-2px)',
              transition: 'all 0.3s ease-in-out'
            }
          }}
        >
          <CardContent>
            <Link 
              href={article.url} 
              target="_blank" 
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'bold',
                  color: theme.palette.text.primary
                }}
              >
                {article.title}
              </Typography>
            </Link>
            <Typography 
              variant="body2" 
              sx={{ 
                position: 'absolute', 
                bottom: 8, 
                right: 8,
                color: theme.palette.text.secondary
              }}
            >
              {article.source}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default HeadlinesColumn;
