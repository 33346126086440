import { fetchFlashOfTheDay } from './newsUtils';
import { getCachedFlashOfTheDay, setCachedFlashOfTheDay } from './cacheUtils';

export async function getFlashOfTheDay() {
  try {
    // Temporarily bypass the cache
    // const cachedArticle = getCachedFlashOfTheDay();
    // if (cachedArticle) return cachedArticle;

    console.log('Fetching fresh Flash of the Day');
    const flashOfTheDay = await fetchFlashOfTheDay();
    if (flashOfTheDay) {
      console.log('Received Flash of the Day:', flashOfTheDay);
      setCachedFlashOfTheDay(flashOfTheDay);
    } else {
      console.error('No valid Flash of the Day found');
    }

    return flashOfTheDay;
  } catch (error) {
    console.error('Error in getFlashOfTheDay:', error);
    return null;
  }
}
