import React, { useState, useEffect } from 'react';
import { Typography, Box, Link, CardMedia, Card, CardContent } from '@mui/material';
import { getFlashOfTheDay } from '../utils/flashOfTheDay';

const FlashOfTheDay = () => {
  const [flashArticle, setFlashArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFlashOfTheDay = async () => {
      try {
        const article = await getFlashOfTheDay();
        if (article) {
          setFlashArticle(article);
        } else {
          setError('Unable to fetch Flash of the Day. Please try again later.');
        }
      } catch (err) {
        console.error('Error fetching Flash of the Day:', err);
        setError('Failed to fetch Flash of the Day. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchFlashOfTheDay();
  }, []);

  if (loading) return <Typography>Loading Flash of the Day...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!flashArticle) return null;

  return (
    <Card sx={{ mb: 4, maxWidth: 800, margin: '0 auto', overflow: 'hidden' }}>
      <CardContent>
        <Typography 
          variant="h5" 
          component="div" 
          gutterBottom 
          align="center" 
          sx={{ 
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: 'primary.main'
          }}
        >
          Flash of the Day
        </Typography>
        {flashArticle.urlToImage && (
          <Box sx={{ position: 'relative', paddingTop: '56.25%', mb: 2 }}>
            <CardMedia
              component="img"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              image={flashArticle.urlToImage}
              alt={flashArticle.title}
            />
          </Box>
        )}
        <Typography variant="caption" color="text.secondary" display="block" align="right" sx={{ mb: 2 }}>
          Source: {flashArticle.source.name}
        </Typography>
        <Link 
          href={flashArticle.url} 
          target="_blank" 
          rel="noopener noreferrer"
          underline="none"
          color="inherit"
        >
          <Typography 
            variant="h6" 
            component="div" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold',
              transition: 'color 0.3s ease-in-out',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            {flashArticle.title}
          </Typography>
        </Link>
        <Typography variant="body1" color="text.secondary">
          {flashArticle.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default FlashOfTheDay;
